import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Divider } from './Components/Catalyst/divider';
import { HoaSchema } from './hoa-api-client';
import { hoaService } from './ApiClients';
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
    Legend,
} from './Components/Catalyst/fieldset';
import { Textarea } from './Components/Catalyst/textarea';
import { Input } from './Components/Catalyst/input';
import { Button } from './Components/Catalyst/button';
import { Checkbox } from './Components/Catalyst/checkbox';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogTitle,
} from './Components/Catalyst/dialog';
import HomeList from './HomeList';

interface TouchedFields {
    [key: string]: boolean;
}

interface FormErrors {
    name?: string;
    street?: string;
    city?: string;
    state?: string;
    zip?: string;
    country?: string;
    contact_phone?: string;
    contact_email?: string;
    website_url?: string;
}

const HOADetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [hoa, setHoa] = useState<HoaSchema | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedHoa, setEditedHoa] = useState<HoaSchema | null>(null);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    useEffect(() => {
        const fetchHoa = async () => {
            try {
                setLoading(true);
                const response = await hoaService.getHoaById({
                    hoaId: Number(id),
                });
                setHoa(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching HOA:', err);
                setError(
                    'Failed to fetch HOA details. Please try again later.'
                );
            } finally {
                setLoading(false);
            }
        };

        fetchHoa();
    }, [id]);

    const handleEdit = () => {
        setEditedHoa(hoa);
        setIsEditing(true);
        setTouchedFields({});
        setFormErrors({});
    };

    const handleSave = async () => {
        if (!editedHoa) return;

        setIsSaving(true);
        setError(null);
        setSuccessMessage(null);

        // Validate all fields before submitting
        const errors: FormErrors = {};
        Object.keys(editedHoa).forEach((key) => {
            const error = validateField(key, (editedHoa as any)[key]);
            if (error) {
                errors[key as keyof FormErrors] = error;
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setIsSaving(false);
            return;
        }

        try {
            await hoaService.updateHoa({
                hoaId: editedHoa.id,
                hoaCreateOrUpdateSchema: editedHoa,
            });
            setHoa(editedHoa);
            setIsEditing(false);
            setSuccessMessage('HOA details updated successfully!');
            setTimeout(() => setSuccessMessage(null), 5000);
        } catch (err) {
            console.error('Error updating HOA:', err);
            setError('Failed to update HOA. Please try again later.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedHoa(hoa);
        setFormErrors({});
        setTouchedFields({});
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (!editedHoa) return;
        const { name, value } = e.target;
        setEditedHoa({ ...editedHoa, [name]: value });
        setTouchedFields({ ...touchedFields, [name]: true });
        const error = validateField(name, value);
        setFormErrors((prev) => ({ ...prev, [name]: error }));
    };

    const handleCheckboxChange = (checked: boolean) => {
        if (!hoa) return;
        setEditedHoa({ ...hoa, is_active: checked });
    };

    const validateField = (name: string, value: any): string => {
        switch (name) {
            case 'name':
            case 'street':
            case 'city':
            case 'state':
            case 'zip':
                return !value
                    ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
                    : '';
            case 'contact_email':
                return !value
                    ? 'Email is required'
                    : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value as string)
                      ? 'Invalid email format'
                      : '';
            case 'contact_phone':
                return !value
                    ? 'Phone is required'
                    : !/^\d{10,11}$/.test(value as string)
                      ? 'Phone must be 10 or 11 digits'
                      : '';
            case 'website_url':
                return value &&
                    !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
                        value as string
                    )
                    ? 'Invalid URL format'
                    : '';
            default:
                return '';
        }
    };

    const handleDialogClose = () => {
        setEditedHoa(hoa);
        setIsEditing(false);
        setFormErrors({});
        setTouchedFields({});
    };

    if (loading) {
        return <div>Loading HOA details...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!hoa) {
        return <div>No HOA found with the given ID.</div>;
    }

    return (
        <SidebarLayout
            sidebar={<AppSidebar currentPage="hoa_detail" />}
            navbar={<Navbar>{/* Your navbar content */}</Navbar>}
        >
            <div className="max-w-3xl mx-auto py-8">
                <div className="flex justify-between items-center">
                    <Heading level={2}>{hoa.name}</Heading>
                    <div>
                        <Button onClick={handleEdit} className="mr-2">
                            Edit HOA Details
                        </Button>
                    </div>
                </div>

                {hoa.description && (
                    <Subheading className="mt-2">{hoa.description}</Subheading>
                )}

                <Divider className="mt-4 mb-6" />

                {successMessage && (
                    <div className="mt-4 p-4 bg-green-100 text-green-700 rounded">
                        {successMessage}
                    </div>
                )}

                {error && (
                    <div className="mt-4 p-4 bg-red-100 text-red-700 rounded">
                        {error}
                    </div>
                )}

                <Fieldset>
                    <Legend>Contact Information</Legend>
                    <FieldGroup>
                        <div className="grid grid-cols-3 gap-6 sm:grid-cols-3">
                            <Field>
                                <Label>Email</Label>
                                <Input
                                    name="contact_email"
                                    value={hoa.contact_email || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>Phone</Label>
                                <Input
                                    name="contact_phone"
                                    value={hoa.contact_phone || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                            <Field>
                                <Label>Website</Label>
                                <Input
                                    name="website_url"
                                    value={hoa.website_url || ''}
                                    readOnly={!isEditing}
                                    onChange={handleInputChange}
                                />
                            </Field>
                        </div>
                    </FieldGroup>
                </Fieldset>

                <Divider className="mt-4 mb-6" />

                <HomeList hoaId={hoa.id}></HomeList>

                <Dialog open={isEditing} onClose={handleDialogClose}>
                    <DialogTitle>Edit HOA</DialogTitle>
                    <DialogBody>
                        <Fieldset>
                            <FieldGroup>
                                <Field>
                                    <Label htmlFor="name">HOA Name</Label>
                                    <Description>required</Description>
                                    <Input
                                        id="name"
                                        name="name"
                                        value={editedHoa?.name || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter HOA name"
                                        required
                                        data-invalid={
                                            touchedFields.name &&
                                            formErrors.name
                                        }
                                    />
                                    {touchedFields.name && formErrors.name && (
                                        <ErrorMessage>
                                            {formErrors.name}
                                        </ErrorMessage>
                                    )}
                                </Field>
                                <Field>
                                    <Label htmlFor="street">Street</Label>
                                    <Description>required</Description>
                                    <Input
                                        id="street"
                                        name="street"
                                        value={editedHoa?.street || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter street address"
                                        required
                                        data-invalid={
                                            touchedFields.street &&
                                            formErrors.street
                                        }
                                    />
                                    {touchedFields.street &&
                                        formErrors.street && (
                                            <ErrorMessage>
                                                {formErrors.street}
                                            </ErrorMessage>
                                        )}
                                </Field>
                                <Field>
                                    <Label htmlFor="street2">Street 2</Label>
                                    <Input
                                        id="street2"
                                        name="street2"
                                        value={editedHoa?.street2 || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter additional street information (optional)"
                                    />
                                </Field>
                                <Field>
                                    <Label htmlFor="city">City</Label>
                                    <Description>required</Description>
                                    <Input
                                        id="city"
                                        name="city"
                                        value={editedHoa?.city || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter city"
                                        required
                                        data-invalid={
                                            touchedFields.city &&
                                            formErrors.city
                                        }
                                    />
                                    {touchedFields.city && formErrors.city && (
                                        <ErrorMessage>
                                            {formErrors.city}
                                        </ErrorMessage>
                                    )}
                                </Field>
                                <Field>
                                    <Label htmlFor="state">State</Label>
                                    <Description>required</Description>
                                    <Input
                                        id="state"
                                        name="state"
                                        value={editedHoa?.state || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter state"
                                        required
                                        data-invalid={
                                            touchedFields.state &&
                                            formErrors.state
                                        }
                                    />
                                    {touchedFields.state &&
                                        formErrors.state && (
                                            <ErrorMessage>
                                                {formErrors.state}
                                            </ErrorMessage>
                                        )}
                                </Field>
                                <Field>
                                    <Label htmlFor="zip">ZIP Code</Label>
                                    <Description>required</Description>
                                    <Input
                                        id="zip"
                                        name="zip"
                                        value={editedHoa?.zip || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter ZIP code"
                                        required
                                        data-invalid={
                                            touchedFields.zip && formErrors.zip
                                        }
                                    />
                                    {touchedFields.zip && formErrors.zip && (
                                        <ErrorMessage>
                                            {formErrors.zip}
                                        </ErrorMessage>
                                    )}
                                </Field>
                                <Field>
                                    <Label htmlFor="country">Country</Label>
                                    <Input
                                        id="country"
                                        name="country"
                                        value={editedHoa?.country || '' || ''}
                                        onChange={handleInputChange}
                                        // onBlur={handleBlur}
                                        placeholder="Enter country"
                                    />
                                </Field>
                                <Field>
                                    <Label htmlFor="contact_email">
                                        Contact Email
                                    </Label>
                                    <Description>required</Description>
                                    <Input
                                        id="contact_email"
                                        name="contact_email"
                                        type="email"
                                        value={editedHoa?.contact_email || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter contact email"
                                        data-invalid={
                                            touchedFields.contact_email &&
                                            formErrors.contact_email
                                        }
                                    />
                                    {touchedFields.contact_email &&
                                        formErrors.contact_email && (
                                            <ErrorMessage>
                                                {formErrors.contact_email}
                                            </ErrorMessage>
                                        )}
                                </Field>
                                <Field>
                                    <Label htmlFor="contact_phone">
                                        Contact Phone
                                    </Label>
                                    <Description>required</Description>
                                    <Input
                                        id="contact_phone"
                                        name="contact_phone"
                                        type="tel"
                                        value={editedHoa?.contact_phone || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter contact phone"
                                        data-invalid={
                                            touchedFields.contact_phone &&
                                            formErrors.contact_phone
                                        }
                                    />
                                    {touchedFields.contact_phone &&
                                        formErrors.contact_phone && (
                                            <ErrorMessage>
                                                {formErrors.contact_phone}
                                            </ErrorMessage>
                                        )}
                                </Field>
                                <Field>
                                    <Label htmlFor="website_url">
                                        Website URL
                                    </Label>
                                    <Input
                                        id="website_url"
                                        name="website_url"
                                        type="url"
                                        value={editedHoa?.website_url || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter website URL"
                                        data-invalid={
                                            touchedFields.website_url &&
                                            formErrors.website_url
                                        }
                                    />
                                    {touchedFields.website_url &&
                                        formErrors.website_url && (
                                            <ErrorMessage>
                                                {formErrors.website_url}
                                            </ErrorMessage>
                                        )}
                                </Field>
                                <Field>
                                    <Label htmlFor="description">
                                        Description
                                    </Label>
                                    <Description>
                                        Provide a brief description of the HOA
                                    </Description>
                                    <Textarea
                                        id="description"
                                        name="description"
                                        value={editedHoa?.description || ''}
                                        onChange={handleInputChange}
                                        //onBlur={handleBlur}
                                        placeholder="Enter HOA description"
                                        rows={3}
                                    />
                                </Field>
                                <Field>
                                    <div className="flex items-center">
                                        <Checkbox
                                            checked={editedHoa?.is_active}
                                            onChange={handleCheckboxChange}
                                            disabled={!isEditing}
                                        />
                                        <Label
                                            className="ml-4"
                                            htmlFor="is_active"
                                        >
                                            {hoa.is_active
                                                ? 'Active'
                                                : 'Disabled'}
                                        </Label>
                                    </div>
                                </Field>
                            </FieldGroup>
                        </Fieldset>
                    </DialogBody>
                    <DialogActions>
                        <Button
                            onClick={handleCancel}
                            color="zinc"
                            disabled={isSaving}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            color="blue"
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </SidebarLayout>
    );
};

export default HOADetailPage;
